<template>
  <input
    class="focus:outline-none block rounded-md border py-1 px-2 text-slate-500 shadow-sm"
    :class="
      invalid
        ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
        : 'border  border-slate-300 focus:border-indigo-500 focus:ring'
    "
    :id="id"
    :placeholder="placeholder"
    :type="type"
    :value="modelValue"
    :style="{
      borderColor:
        focus && company.primary && company.primary[500]
          ? company.primary[500]
          : null,
      '--tw-ring-color':
        company.primary && company.primary[200] ? company.primary[200] : null,
    }"
    @input="emit('update:modelValue', $event.target.value)"
    @focus="focus = true"
    @focusout="focus = false"
  />
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    id: {
      required: false,
      type: String,
    },
    invalid: {
      default: false,
      required: false,
      type: Boolean,
    },
    modelValue: {
      type: [Number, String],
    },
    placeholder: {
      required: false,
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(_, { emit }) {
    const store = useStore();
    const focus = ref(false);
    return {
      company: computed(() => store.state.company.company),
      emit,
      focus,
    };
  },
};
</script>
